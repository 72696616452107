import React from 'react'
import style from './LoadingComponent.module.css'
export default function LoadingComponent() {
    return (
        <div style={{}}>
            <div className={style.dark}>

                <div className={style.bg}>

                </div>
            </div>
        </div>
    )
}
